'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.incidents.factory:IncidentsManager

 # @description

###
angular
  .module 'mundoAdmin.incidents'
  .factory 'IncidentsManager',[
    'MundoDefaultManager'
    '$state'
    '$timeout'
    '$rootScope'
    (
      MundoDefaultManager
      $state
      $timeout
      $rootScope
    )->
      IncidentsManagerBase = new MundoDefaultManager()

      IncidentsManagerBase.setUrl('lpa/incidents')
      IncidentsManagerBase.setActions(['list', 'print', 'search'])
      IncidentsManagerBase.setSearchFields(['externalId', 'incidentType.label'])
      IncidentsManagerBase.defaultQuery.sort = 'externalId'

      IncidentsManagerBase.getListSchema = ->
        [
          key: 'externalId'
          title: 'datatable.label.incidentId'
          sort: 'externalId'
        ,
          key: 'incidentType.label'
          title: 'datatable.label.incidentType'
          sort: 'incidentType.label'
        ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
        ,
          key: 'closedAt'
          title: 'datatable.label.closed.at'
          sort: 'closedAt'
          type: 'dateTime'
          hideInList: true
        ,
          key: 'locationDescription'
          title: 'Location description'
          hideInList: true
        ,
          key: 'coordinates'
          title: 'Location coordinates'
          hideInList: true
        ,
          key: 'reporterName'
          title: 'Reporter name'
          hideInList: true
        ,
          key: 'reporterPhoneNumber'
          title: 'Reporter phone number'
          hideInList: true
        ]

      IncidentsManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/incidents/views/incident-detail.tpl.html'

      IncidentsManagerBase.valuePostProcessor = (path, value, row) ->
        if path == 'coordinates'
          lat = row.locationLatitude
          lon = row.locationLongitude
          return "#{lat} , #{lon}"

        return value

      IncidentsManagerBase.getSchemaLinks = ->
        [
          key: 'label'
          title: 'Tasks'
          multiple: 'tasks'
          hideInList: true
          action: (part) ->
            contactlabel = part.label
            $state.go('tasks.tasks').then(->
              $timeout(->
                $rootScope.$broadcast('searchFor',
                  {term: contactlabel, to: 'app.admin.pageTitles.tasks.tasks'})
              ,100)
            )
        ]

      unselectedIcon = 'add_circle_outline'
      unselectedText = 'show all incidents'

      selectedIcon = 'remove_circle_outline'
      selectedText = 'show only active incidents'

      filterOnClosed =
        [
          icon: unselectedIcon
          text: unselectedText
          action: (ctrl) ->
            if @.icon == selectedIcon
              @.icon = unselectedIcon
              @.text = unselectedText
            else
              @.icon = selectedIcon
              @.text = selectedText

            show = IncidentsManagerBase.filterClosed()
            # reload the items in the list
            loadFilterParams = $state.$current.locals['incidents@incidents'].$scope.listCtrl.loadFilterParams
            if show
              loadFilterParams['filter[closedAt]'] = undefined
            else
              loadFilterParams['filter[closedAt]'] = 'OR,closedAt,NULL,closedAt,GTE ' + moment.utc().format()

            $state.$current.locals['incidents@incidents'].$scope.listCtrl.loadFilterParams = loadFilterParams
            $state.$current.locals['incidents@incidents'].$scope.listCtrl.schema[3].hideInList = !show
            $state.$current.locals['incidents@incidents'].$scope.listCtrl.load()
        ]

      IncidentsManagerBase.filterClosed = () =>
        @showClosed = !@showClosed
        @showClosed

      IncidentsManagerBase.addTableAction(filterOnClosed)

      IncidentsManagerBase.showClosed = false

      IncidentsManagerBase
  ]
